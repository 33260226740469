import React from 'react'
import AirField from './component/AirField'
import useSocketIO from './hooks/useSocketIO'

const REACT_APP_SOCKETIO_URL =
  process.env.REACT_APP_SOCKETIO_URL || 'https://ws.eslovsflygklubb.se'

const toRadians = (angle) => angle * (Math.PI / 180)

const createLane = (name, magneticTrack, wind) => {
  const crosswind =
    Math.sin(toRadians(wind.direction - magneticTrack)) * wind.speed
  const headwind =
    Math.cos(toRadians(wind.direction - magneticTrack)) * wind.speed
  const active = Math.abs(wind.direction - magneticTrack) < 90 ? true : false

  return { name, crosswind, headwind, wind, active }
}

function App() {
  const { data, error } = useSocketIO(REACT_APP_SOCKETIO_URL)
  const weatherData = data?.weather?.data?.conditions

  if (error) return <div>failed to load</div>
  if (!weatherData) return <div>loading...</div>

  const props = {
    speed: weatherData?.converted?.wind_speed_avg_last_10_min_kt,
    direction: weatherData?.wind_dir_at_hi_speed_last_10_min,
    temp: weatherData?.converted?.temp_c,
    dewpoint: weatherData?.converted?.dew_point_c,
    qnh: weatherData?.converted?.bar_sea_level_hpa,
    rain: weatherData?.rain_rate_last,
    speedHigh: weatherData?.converted?.wind_speed_hi_last_2_min_kt,
    lastReceived: data?.weather?.data?.ts,
    rainRate: weatherData?.rain_rate_hi_last_15_min,
    lightsOn: data?.status.belysning?.bana?.state || false,
  }

  return (
    <div className="Weather">
      <Weather {...props} />
    </div>
  )
}

const Weather = ({
  speed,
  direction,
  temp,
  dewpoint,
  qnh,
  speedHigh,
  lastReceived,
  rainRate,
  lightsOn,
}) => {
  const lastReceivedDate = new Date(lastReceived * 1000)
  const ddhhmmZ =
    lastReceivedDate.getUTCDate().toString().padStart(2, '0') +
    lastReceivedDate.getUTCHours().toString().padStart(2, '0') +
    lastReceivedDate.getUTCMinutes().toString().padStart(2, '0') +
    'Z'

  const runway12 = createLane('12', 120, { speed, direction })
  const runway30 = createLane('30', 300, { speed, direction })
  const runway = [runway12, runway30].find(({ active }) => active) || runway12 // No wind defaults to 12

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AirField
        runway={runway}
        lightsOn={lightsOn}
        width="100%"
        height="100%"
      />
      <div
        style={{
          position: 'absolute',
          left: '0',
          bottom: '0',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ textAlign: 'start', padding: '30px' }}>
            <b>METAR</b>
            <br />
            <b>ESME</b> {ddhhmmZ} AUTO {direction.toString().padStart(3, '0')}
            {Math.round(speed).toString().padStart(2, '0')}
            {speedHigh - speed > 10 ? 'G' + speedHigh : ''}{' '}
            {rainRate > 0.0 ? 'RA ' : ''}NCD
            {Math.round((((temp - dewpoint) / 2.5) * 1000 + 296) / 100)
              .toString()
              .padStart(3, '0')}{' '}
            {Math.floor(temp).toString().padStart(2, '0')}/
            {Math.floor(dewpoint).toString().padStart(2, '0')} Q
            {Math.ceil(qnh).toString().padStart(4, '0')}
          </div>
          <div style={{ display: 'none' }}>
            {Math.floor(temp)}ºC / {Math.ceil(dewpoint)}ºC
            <br />
            QNH: {Math.ceil(qnh).toString().padStart(4, '0')}
            <br />
            Cloudbase:NCD
            {Math.round((((temp - dewpoint) / 2.5) * 1000 + 296) / 100)
              .toString()
              .padStart(3, '0')}
            <br />
            Rain Rate: {rainRate} mm/h
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
