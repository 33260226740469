import React from 'react'
import { io } from 'socket.io-client'

export const useSocketIO = (url) => {
  const [data, setData] = React.useState(null)
  const [error, setError] = React.useState(null)
  React.useEffect(() => {
    const socket = io(url)
    socket.on('weather:data', (data) => {
      setData((prev) => ({ ...prev, weather: { ...data } }))
    })
    socket.on('status', (data) => {
      setData((prev) => ({ ...prev, status: { ...data } }))
    })
    socket.on('connect', () => {
      setError(null)
      socket.emit('status', (data) => {
        setData((prev) => ({ ...prev, status: { ...data } }))
      })
      socket.emit('weather:data')
    })

    socket.on('disconnect', () => {})

    socket.on('connect_error', (error) => {
      if (!socket.active) {
        console.log(error.message)
        setError(error.message)
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [url])

  return { data, error, loading: !data && !error }
}

export default useSocketIO
